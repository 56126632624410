import React from "react";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Card,
  Link,
  Avatar,
} from "@mui/material";
import { Google } from "@mui/icons-material"; // Import Google icon
import { auth, googleProvider, signInWithPopup } from "../firebase/firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import logo from "../assets/logo.jpeg"; // Make sure to import your logo here

export default function PluginLogin() {
  const sendTokenToRedirectUri = async (jwtToken, userEmail) => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUri = urlParams.get("redirect_uri");

    // Store token and email in session storage to avoid exposing in URL
    sessionStorage.setItem("jwtToken", jwtToken);
    sessionStorage.setItem("userEmail", userEmail);
  
    // Redirect to sendToken.html, passing the redirect URI as a parameter
    const redirectIntermediateUri = `https://bamroc.ai/sendToken.html?redirect_uri=${encodeURIComponent(redirectUri)}`;
    window.location.href = redirectIntermediateUri;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const idToken = await user.getIdToken();

      const response = await fetch("https://bamroc.ai/api/users/firebase-login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      });

      const json = await response.json();
      if (json.success) {
        const jwtToken = json.token;
        const userEmail = json.email;
        await sendTokenToRedirectUri(jwtToken, userEmail);
      } else {
        alert("Failed to log in");
      }
    } catch (error) {
      console.error("Error during email/password login:", error);
      alert("Invalid Credentials");
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const idToken = await user.getIdToken();

      const response = await fetch("https://bamroc.ai/api/users/google-login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      });

      const json = await response.json();
      if (json.success) {
        const jwtToken = json.token;
        const email = json.email;
        await sendTokenToRedirectUri(jwtToken, email);
      } else {
        alert("Google Sign-In failed");
      }
    } catch (error) {
      console.error("Error during Google Sign-In", error);
      alert("Google Sign-In failed");
    }
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Card
        sx={{
          padding: 4,
          borderRadius: 3,
          boxShadow: 5,
          textAlign: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
          <Avatar
            src={logo}
            alt="Product Logo"
            sx={{ width: 56, height: 56, mb: 1 }} // Adjust size as needed
          />
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            color="primary"
            gutterBottom
            sx={{ fontFamily: "Roboto, sans-serif" }}
          >
            BAMROC Login
          </Typography>
        </Box>

        <Box component="form" onSubmit={handleSubmit}>
          <Typography
            variant="body2"
            color="text.secondary"
            mb={3}
            sx={{ color: "#6b6b6b" }}
          >
            Sign in to continue
          </Typography>

          <TextField
            fullWidth
            margin="normal"
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            required
            sx={{
              bgcolor: "#f8f9fa",
              "& .MuiInputLabel-root": { color: "#7d7d7d" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#c4c4c4" },
                "&:hover fieldset": { borderColor: "#1976d2" },
                "&.Mui-focused fieldset": { borderColor: "#1976d2" },
              },
            }}
          />

          <TextField
            fullWidth
            margin="normal"
            name="password"
            label="Password"
            type="password"
            variant="outlined"
            required
            sx={{
              bgcolor: "#f8f9fa",
              "& .MuiInputLabel-root": { color: "#7d7d7d" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "#c4c4c4" },
                "&:hover fieldset": { borderColor: "#1976d2" },
                "&.Mui-focused fieldset": { borderColor: "#1976d2" },
              },
            }}
          />

          <Box mt={2} textAlign="right">
            <Link href="/forgotpassword" color="secondary" variant="body2" underline="hover">
              Forgot password?
            </Link>
          </Box>

          <Box mt={3} textAlign="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              sx={{
                fontWeight: "bold",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Login
            </Button>
          </Box>

          <Box mt={2} textAlign="center">
            <Button
              onClick={handleGoogleSignIn}
              variant="outlined"
              color="secondary"
              size="large"
              fullWidth
              startIcon={<Google />}
              sx={{
                mt: 2,
                fontWeight: "bold",
                fontFamily: "Roboto, sans-serif",
                borderColor: "#1976d2",
                "&:hover": {
                  borderColor: "#005cbf",
                },
              }}
            >
              Sign in with Google
            </Button>
          </Box>

          <Box mt={4} textAlign="center">
            <Typography variant="body2" color="text.secondary" sx={{ color: "#6b6b6b" }}>
              Don&apos;t have an account?{" "}
              <Link href="/register" color="primary" underline="hover">
                Sign Up
              </Link>
            </Typography>
          </Box>
        </Box>
      </Card>
    </Container>
  );
}
