import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./styles/theme";
import Login from "./screens/Login";
import Register from "./screens/Register";
import Dashboard from "./screens/Dashboard";
import PluginLogin from "./screens/PluginLogin";
import ForgotPassword from "./screens/ForgotPassword";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}> {/* Wrap ThemeProvider outside Router */}
        <Router>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/dashboard" element={<Dashboard />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/revitlogin" element={<PluginLogin />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
