import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Container,
  CssBaseline,
  Grid,
  TextField,
  Typography,
  Link,
  Avatar,
} from "@mui/material";
import { Google } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { auth, googleProvider, signInWithPopup } from "../firebase/firebaseConfig";
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile } from "firebase/auth";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import logo from "../assets/logo.jpeg";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Vavetek.AI
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Register() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [companyName, setCompanyName] = useState(""); // New state for company name

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const name = data.get("name");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
      return;
    } else {
      setEmailError("");
    }

    if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters long");
      return;
    } else {
      setPasswordError("");
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    } else {
      setConfirmPasswordError("");
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      await updateProfile(user, { displayName: name });
      await sendEmailVerification(user);

      const idToken = await user.getIdToken();

      const response = await fetch("https://bamroc.ai/api/users/firebase-register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          email,
          name,
          companyName, // Include company name in request body
        }),
      });


      if (response.ok) {
        alert("Registration successful! A verification email has been sent to your email address.");
        navigate("/login");
      } else {
        console.error("Failed to add user to Firestore.");
        alert("Error occurred while adding user to Firestore.");
      }
    } catch (error) {
      console.error("Error during registration:", error);
      if (error.code === "auth/email-already-in-use") {
        setEmailError("This email is already in use. Please use a different email.");
      } else {
        alert(error.message);
      }
    }
  };

  // Real-time password confirmation validation
  useEffect(() => {
    if (confirmPassword && password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  }, [password, confirmPassword]);

  const handleGoogleSignUp = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const idToken = await user.getIdToken();

      const response = await fetch("https://bamroc.ai/api/users/google-login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
        body: JSON.stringify({
          companyName, // Include company name in Google Sign-Up request body
        }),
      });

      const json = await response.json();
      if (json.success) {
        console.log(json.message);
        localStorage.setItem("jwttoken", json.token);
        localStorage.setItem("user", json.displayName);
        localStorage.setItem("email", json.email);
        navigate(`/dashboard`);
      } else {
        alert("Google Sign-Up failed");
      }
    } catch (error) {
      console.error("Error during Google Sign-Up", error);
      alert("Google Sign-Up failed");
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          minHeight: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#f0f2f5",
        }}
      >
        <Card
          sx={{
            padding: 4,
            borderRadius: 3,
            boxShadow: 5,
            textAlign: "center",
            backgroundColor: "#ffffff",
          }}
        >
          <CssBaseline />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {/* Logo Image */}
            <Avatar
              src={logo}
              alt="Product Logo"
              sx={{ width: 56, height: 56, mb: 1 }}
            />

            {/* Main Heading */}
            <Typography component="h1" variant="h5" fontWeight="bold" color="primary">
              Sign Up for BAMROC
            </Typography>

            {/* Subheading Text */}
            <Typography component="p" variant="subtitle1" color="text.secondary" sx={{ mt: 1 }}>
              Please fill the following details
            </Typography>

            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="name"
                    name="name"
                    required
                    fullWidth
                    id="name"
                    label="Name"
                    variant="outlined"
                    InputLabelProps={{
                      style: { color: "#7d7d7d" },
                    }}
                    InputProps={{
                      style: { backgroundColor: "#f8f9fa" },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    autoComplete="companyname"
                    name="companyname"
                    fullWidth
                    id="companyname"
                    label="Company Name"
                    variant="outlined"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    InputLabelProps={{
                      style: { color: "#7d7d7d" },
                    }}
                    InputProps={{
                      style: { backgroundColor: "#f8f9fa" },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    variant="outlined"
                    error={!!emailError}
                    helperText={emailError}
                    InputLabelProps={{
                      style: { color: "#7d7d7d" },
                    }}
                    InputProps={{
                      style: { backgroundColor: "#f8f9fa" },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="new-password"
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    error={!!passwordError}
                    helperText={passwordError}
                    InputLabelProps={{
                      style: { color: "#7d7d7d" },
                    }}
                    InputProps={{
                      style: { backgroundColor: "#f8f9fa" },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirmPassword"
                    autoComplete="new-password"
                    variant="outlined"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    error={!!confirmPasswordError}
                    helperText={confirmPasswordError}
                    InputLabelProps={{
                      style: { color: "#7d7d7d" },
                    }}
                    InputProps={{
                      style: { backgroundColor: "#f8f9fa" },
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                sx={{
                  mt: 3,
                  fontWeight: "bold",
                  fontFamily: "Roboto, sans-serif",
                }}
              >
                Sign Up
              </Button>

              {/* Google Sign-Up Button */}
              <Button
                onClick={handleGoogleSignUp}
                variant="outlined"
                color="secondary"
                size="large"
                fullWidth
                startIcon={<Google />}
                sx={{
                  mt: 2,
                  fontWeight: "bold",
                  fontFamily: "Roboto, sans-serif",
                  borderColor: "#1976d2",
                  color: "#b0b0b0", // Light gray text color
                  "&:hover": {
                    borderColor: "#005cbf",
                    color: "#8c8c8c", // Slightly darker gray on hover
                  },
                }}
              >
                Sign up with Google
              </Button>

              <Grid container justifyContent="center" sx={{ mt: 2 }}>
                <Grid item>
                  <Link href="/login" color="primary" underline="hover">
                    Already have an account? Sign in
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Card>
      </Container>
    </ThemeProvider>
  );
}
