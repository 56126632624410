import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Card,
  Link,
  Avatar,
} from "@mui/material";
import { Google } from "@mui/icons-material"; // Google icon
import { auth, googleProvider, signInWithPopup } from "../firebase/firebaseConfig";
import { signInWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import logo from "../assets/logo.jpeg"; // Import the logo image

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Vavetek.AI
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Login() {
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Check if email is verified
      if (user.emailVerified) {
        // Get JWT token to authenticate with backend
        const idToken = await user.getIdToken();

        const response = await fetch("https://bamroc.ai/api/users/firebase-login", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${idToken}`,
          },
        });
        console.log(response);
        const json = await response.json();
        console.log(json);
        if (json.success) {
          localStorage.setItem("jwttoken", json.token || idToken);
          localStorage.setItem("user", json?.displayName || user.displayName || "Anonymous");
          localStorage.setItem("email", json?.email || user.email);
          navigate(`/dashboard`);
        } else {
          alert("Failed to log in");
        }
      } else {
        alert("Please verify your email before logging in.");
        
        // Optionally, prompt to resend verification email
        if (window.confirm("Would you like us to resend the verification email?")) {
          await sendEmailVerification(user);
          alert("Verification email resent. Please check your inbox.");
        }
      }
    } catch (error) {
      if(error.code === "auth/too-many-requests") alert("Too many requests. Please try again later");
      else alert("Invalid Credentials");
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const idToken = await user.getIdToken();

      const response = await fetch("https://bamroc.ai/api/users/google-login", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${idToken}`,
        },
      });

      const json = await response.json();
      if (json.success) {
        console.log(json.message);
        localStorage.setItem("jwttoken", json.token);
        localStorage.setItem("user", json.displayName);
        localStorage.setItem("email", json.email);
        navigate(`/dashboard`);
      } else {
        alert("Google Sign-In failed");
      }
    } catch (error) {
      console.error("Error during Google Sign-In", error);
      alert("Google Sign-In failed");
    }
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#f0f2f5",
      }}
    >
      <Card
        sx={{
          padding: 4,
          borderRadius: 3,
          boxShadow: 5,
          textAlign: "center",
          backgroundColor: "#ffffff",
        }}
      >
        <Box display="flex" flexDirection="column" alignItems="center" mb={2}>
          <Avatar
            src={logo}
            alt="Product Logo"
            sx={{ width: 56, height: 56, mb: 1 }}
          />
          <Typography
            variant="h5"
            component="h2"
            fontWeight="bold"
            color="primary"
            gutterBottom
          >
            BAMROC Login
          </Typography>
        </Box>

        <Box component="form" onSubmit={handleSubmit}>
          <Typography
            variant="body2"
            color="text.secondary"
            mb={3}
            sx={{ color: "#6b6b6b" }}
          >
            Please enter your email and password
          </Typography>

          <TextField
            fullWidth
            margin="normal"
            name="email"
            label="Email"
            type="email"
            variant="outlined"
            required
            InputLabelProps={{
              style: { color: "#7d7d7d" },
            }}
            InputProps={{
              style: { backgroundColor: "#f8f9fa" },
            }}
          />

          <TextField
            fullWidth
            margin="normal"
            name="password"
            label="Password"
            type="password"
            variant="outlined"
            required
            InputLabelProps={{
              style: { color: "#7d7d7d" },
            }}
            InputProps={{
              style: { backgroundColor: "#f8f9fa" },
            }}
          />

          <Box mt={2} textAlign="right">
            <Link href="/forgotpassword" color="secondary" variant="body2" underline="hover">
              Forgot password?
            </Link>
          </Box>

          <Box mt={3} textAlign="center">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              sx={{
                fontWeight: "bold",
                fontFamily: "Roboto, sans-serif",
              }}
            >
              Login
            </Button>
          </Box>

          <Box mt={2} textAlign="center">
            <Button
              onClick={handleGoogleSignIn}
              variant="outlined"
              color="secondary"
              size="large"
              fullWidth
              startIcon={<Google />}
              sx={{
                mt: 2,
                fontWeight: "bold",
                fontFamily: "Roboto, sans-serif",
                borderColor: "#1976d2",
                "&:hover": {
                  borderColor: "#005cbf",
                },
              }}
            >
              Sign in with Google
            </Button>
          </Box>

          <Box mt={4} textAlign="center">
            <Typography variant="body2" color="text.secondary">
              Don't have an account?{" "}
              <Link href="/register" color="primary" underline="hover">
                Sign Up
              </Link>
            </Typography>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Card>
    </Container>
  );
}
